.home-head {
  grid-area: home-head;
}

.away-head {
  grid-area: away-head;
}

.scoreboard {
  font-weight: bolder;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  text-align: center;
  container-name: scoreboard;
  container-type: inline-size;
}

.quarter {
  grid-area: quarter;
  color: white;
  text-transform: uppercase;
  font-size: 34px;
  align-items: center;
  justify-items: center;
}

.quarter-inner {
    width: 80px;
    margin: 20% 40% 7% 40%;
}

.team-name {
  font-size: 52px;
  padding: 3%;
}

@container scoreboard (width < 950px) {
  .team-name {
    font-size: 24px;
  }
  .quarter {
    font-size: 16px;
  }
  .quarter-inner {
    width: 10px;
  }
}

@container scoreboard (width < 450px) {
  .team-name {
    font-size: 13px;
  }
  .quarter {
    font-size: 12px;
  }
  .quarter-inner {
    width: 16px;
  }
}


.home-score {
  grid-area: home-score;
  padding: 8%;
}

.away-score {
  grid-area: away-score;
  padding: 8%;
}

/* The Scoreboard */
.scoreboard {
  display: grid;
  padding: 3%;
  grid-template-areas:
    'home-head empty away-head'
    'home-score quarter away-score';
  grid-template-columns: 5fr 3fr 5fr;

  background-color: rgba(0, 0, 0, 1);
  border: 10px solid white;
  border-radius: 15px;
}

.scoreboard-number .numberparent .c0 {
  grid-area: c0;
}

.scoreboard-number .numberparent .c1 {
  grid-area: c1;
}

.scoreboard-number .numberparent .c2 {
  grid-area: c2;
}

.scoreboard-number .numberparent .c3 {
  grid-area: c3;
}

.scoreboard-number .numberparent {
  background-color: black;
}

.scoreboard-number .grid1 {
  display: grid;
  grid-template-areas: 'c0';
}

.scoreboard-number .grid2 {
  display: grid;
  grid-template-areas: 'c0 c1';
}

.scoreboard-number .grid3 {
  display: grid;
  grid-template-areas: 'c0 c1 c2';
}

.scoreboard-number .grid4 {
  display: grid;
  grid-template-areas: 'c0 c1 c2 c3';
}

.scoreboard-number .number {
  height: 0;
  padding-bottom: 129.2%;
  background-image: url('scoreboard-yellow.svg');
  background-size: 2000%;
  background-repeat: no-repeat;
}

.scoreboard-number .red {
  background-position-y: 43%;
}

.scoreboard-number .green {
  background-position-y: 0%;
}

.scoreboard-number .yellow {
  background-position-y: 86%;
}

.scoreboard-number .one {
  background-position-x: 0%;
}

.scoreboard-number .two {
  background-position-x: 10%;
}

.scoreboard-number .three {
  background-position-x: 20%;
}

.scoreboard-number .four {
  background-position-x: 30%;
}

.scoreboard-number .five {
  background-position-x: 40%;
}

.scoreboard-number .six {
  background-position-x: 50%;
}

.scoreboard-number .seven {
  background-position-x: 60%;
}

.scoreboard-number .eight {
  background-position-x: 70%;
}

.scoreboard-number .nine {
  background-position-x: 80%;
}

.scoreboard-number .zero {
  background-position-x: 90%;
}

.scoreboard-number .blank {
  background-position-x: 100%;
}
