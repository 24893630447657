div.steppedprogress-container {
  margin-top: 0px;
  padding-top: 10px;
  border-bottom: 1px solid #ccc;
}

.steppedprogress-container ul {
  list-style: none;
}

.steppedprogress {
  margin: 0 0 30px 0;
  padding: 0;
  overflow: hidden;
  color: black;
  counter-reset: step;
}
.steppedprogress li {
  list-style-type: none;
  float: left;
  width: 25%;
  position: relative;
  text-align: center;
  font-family: 'Roboto', Helvetica;
  font-size: 12px;
  color: #797979;
  text-transform: uppercase;
  font-weight: bolder;

  /* font-weight: 500; */
}

.steppedprogress li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border: 2px solid #000;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
  /* font-family: Ionicons; */
  /* font-size: 25px; */
}

.steppedprogress li:after {
  content: '';
  position: absolute;
  width: 80%;
  height: 3px;
  background-color: #333333;
  top: 15px;
  left: 60%;
  /* z-index: -1; */
}

.steppedprogress li:last-child:after {
  height: 0px;
}

.steppedprogress li.active {
  color: black;
  font-weight: bolder;
}

.steppedprogress li.done {
  /* color: green; */
  font-weight: bolder;
}

.steppedprogress li.done:before {
  content: '\2713';
  background-color: #ccc;
}
/*.steppedprogress li:first-child:after {
      content: none;
    }
    
    .steppedprogress li.active:after {
      color: green;
    }
    
    .steppedprogress li.active + li:after {
      background-color: green;
    } */
