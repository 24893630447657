.tile {
  width: 100%;
}

.tile .row {
  width: 100%;
}

.tile .col {
  float: left;
  background-color: bisque;
}
