/* Player Button */
div.player-button {
  padding: 0 0 0 0;
  margin: 2px 2px 2px 0px;
  border-radius: 6px;
}

.player-button button {
  margin: 0;
  padding: 0;
  color: white;
  background-color: black;
  border-radius: 6px;
  width: 100%;
}

.player-button > div {
  float: left;
  border-radius: 8px;
  padding: 4px;
  margin: 2px 0px 2px 2px;
  border: 0;
}

.player-button div.number {
  color: black;
  height: 100%;
  width: 30px;
  background-color: white;
  border-radius: 6px;
}

.player-button div.name {
  margin-left: 4%;
}
