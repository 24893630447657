div.boxscore {
  width: 100%;
}

.boxscore {
  font-size: small;
  padding: 0;
  margin: 0;
  border: 0;
  border-spacing: 0;
}

.boxscore table {
  width: 100%;
  background: white;
  color: black;
  border-spacing: 0;
}

.boxscore td {
  padding: 4px;
}
.boxscore thead tr {
  background-color: #eee;
}

.boxscore div.team-name {
  color: black;
  font-weight: bold;
  background-color: white;
  text-align: center;
  font-size: 16px;

  width: 100%;
}

.boxscore tr.team {
  background: black;
  color: white;
  font-weight: bold;
}

.boxscore td > div {
  float: left;
}
.boxscore td.player {
  content: '';
  clear: both;
  display: table;
}

.boxscore td div.number {
  width: 30px;
  text-align: right;
  padding-right: 4px;
}

.boxscore td {
  text-align: right;
  /* padding-right: 8px; */
}

.boxscore th {
  cursor: pointer;
}
.boxscore th.sorting {
  background: black;
  color: white;
}

.boxscore .header {
  color: white;
  background: rgba(34, 34, 17, 0.8);
  padding: 8px;
  border-top-left-radius: 8px;
}

div.boxscore {
  padding: 20px;
}

.boxscore button.link-button:disabled {
  color: #fff;
}
.boxscore button.link-button {
  color: #ccc;
}

div.small-hundred {
  width: 50%;
  float: left;
}

@media screen and (max-width: 1200px) {
  div.small-hundred {
    float: left;
    width: 100%;
  }
}
