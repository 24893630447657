/* Event Viewer */
div.event-viewer {
  width: 100%;
  padding: 10px;
}

.event-viewer div.rows {
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
  background: white;
  color: black;
  height: 220px;
  width: 100%;
}

.event-viewer table {
  padding: 0;
  margin: 0;
  width: 100%;
  border-spacing: 0px;
  background-color: white;
}

.event-viewer td {
  height: 40px;
  padding: 8px 10px 8px 10px;
  font-weight: bold;
  /* background: pink; */
}

.event-viewer tr.home {
  /* background: rgba(34, 34, 17, 0.8); */
  background: transparent;
  background: linear-gradient(
    90deg,
    rgba(34, 34, 17, 1) 0%,
    rgba(34, 34, 17, 0.5) 7%,
    rgba(34, 34, 17, 0.1) 9%,
    rgba(34, 34, 17, 0) 11%,
    rgba(34, 34, 17, 0) 100%
  );
}
.event-viewer tr.away {
  background: transparent;
  background: linear-gradient(
    90deg,
    rgba(34, 34, 17, 0) 0%,
    rgba(34, 34, 17, 0) 89%,
    rgba(34, 34, 17, 0.1) 91%,
    rgba(34, 34, 17, 0.5) 93%,
    rgba(34, 34, 17, 1) 100%
  );
}

.event-viewer td.side {
  color: white;
  font-weight: bold;
  font-size: 10px;
  transform: rotate(90deg);
  text-align: center;
  width: 8px;
}

.event-viewer .player > div {
  float: left;
  border-radius: 8px;
  padding: 4px;
  margin: 0px 0px 0px 0px;
}

.event-viewer .player div.number {
  color: black;
  height: 100%;
  width: 30px;
  background-color: black;
}

.event-viewer td.what {
  font-size: 14px;
}

.event-viewer button {
  color: black;
}

.event-viewer .delete {
  color: crimson;
}

.event-viewer .filter {
  padding-left: 12px;
  padding-top: 8px;
  padding-bottom: 4px;
  height: 40px;
  background-color: white;
}

.event-viewer .filter input {
  width: 70%;
  margin-right: 7px;
}

.event-viewer .filter button {
  color: #222;
  font-weight: bold;
}

.event-viewer .filter button:disabled {
  color: #666;
  cursor: default;
}

.event-viewer .header {
  color: white;
  background: rgba(34, 34, 17, 0.8);
  padding: 8px;
  border-top-left-radius: 8px;
}
