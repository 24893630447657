div.login-required {
  width: 30%;
  margin-left: 20%;
}

.login-required ul {
  list-style-type: circle;
  list-style: circle;
  list-style-position: outside;
  padding-left: 30px;
}

.login-required .content {
  padding: 20px;
}
.login-required > div {
  background-color: rgba(255, 255, 255, 0.8);
  /* padding: 10px; */
  border-radius: 12px;
  color: black;
  font-size: 20px;
}

.help {
  border-radius: 12px 12px 0 0;
  padding: 10px;
  color: white;
  font-size: 24px;
  background-color: black;
}

.login-required button {
  border: 0;
  width: 191px;
  height: 46px;
  background: url(btn_google_signin_dark_normal_web@2x.png) no-repeat;
  background-size: contain;
  margin-left: 40px;
  margin-top: 40px;
}

button.avatar {
  border-radius: 50%;
  background-size: auto 100%;
  color: #000000;
  cursor: pointer;
  height: 32px;
  padding-bottom: 2px;
  width: 32px;
}

button.avatar:hover,
button.avatar:focus {
  border-color: #2980b9;
}
