div.game-setup {
  margin-top: 20px;
  margin-left: 16%;
  width: 800px;
}

.game-setup input {
  width: 100%;
  padding: 8px;
  margin-top: 8px;
}

.game-setup label {
  font-size: 16px;
  font-weight: bold;
}

.game-setup button {
  width: 200px;
  min-height: 30px;
}

h1 {
  text-align: center;
  color: black;
}
/* .game-setup div.form {
  padding: 1%;
  color: black;
} */

.game-setup div.container {
  display: flex;
  padding: 20px;
}

.game-setup p {
  margin: 4px;
}
.game-setup div.space {
  display: flex;
  justify-content: space-around;
}

.game-setup div.item {
  padding: 20px;
}

.game-setup .header {
  color: white;
  background: rgba(34, 34, 17, 0.8);
  padding: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.game-setup .content {
  width: 100%;
  color: black;
  background-color: white;
  padding: 8px;
  font-size: 24px;
}

.game-setup div.left {
  float: left;
  width: 40%;
  margin-left: 8%;
}

.game-setup div {
  font-size: 16px;
}

.game-setup div.clearfix > div {
  float: left;
}
div.progressbar-container {
  margin-top: 0px;
  padding-top: 10px;
  border-bottom: 1px solid #ccc;
}

.progressbar-container ul {
  list-style: none;
}

.progressbar {
  margin: 0 0 30px 0;
  padding: 0;
  overflow: hidden;
  color: black;
  counter-reset: step;
}
.progressbar li {
  list-style-type: none;
  float: left;
  width: 25%;
  position: relative;
  text-align: center;
  font-family: 'Roboto', Helvetica;
  font-size: 12px;
  color: #797979;
  text-transform: uppercase;
  font-weight: bolder;

  /* font-weight: 500; */
}

.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border: 2px solid #000;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
  /* font-family: Ionicons; */
  /* font-size: 25px; */
}

.progressbar li:after {
  content: '';
  position: absolute;
  width: 80%;
  height: 3px;
  background-color: #333333;
  top: 15px;
  left: 60%;
  /* z-index: -1; */
}

.progressbar li:last-child:after {
  height: 0px;
}

.progressbar li.active {
  color: black;
  font-weight: bolder;
}

.progressbar li.done {
  /* color: green; */
  font-weight: bolder;
}

.progressbar li.done:before {
  content: '\2713';
  background-color: #ccc;
}
/*.progressbar li:first-child:after {
    content: none;
  }
  
  .progressbar li.active:after {
    color: green;
  }
  
  .progressbar li.active + li:after {
    background-color: green;
  } */
