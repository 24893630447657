/* Actions */
.stat-actions div.action {
  width: 50%;
  float: left;
}

.stat-actions div.saving {
  font-size: 18px;
  color: black;
  font-weight: bold;
}

.stat-actions div.action > button {
  font-weight: bolder;
  background-color: white;
  min-height: 50px;
  /* border: 1px 0px 3px 0px solid rgb(5, 4, 43); */
  /* border-radius: 5px; */
  padding-top: 2%;
  /* border-top-left-radius: 6px; */
  /* border-top-right-radius: 6px; */
  padding-bottom: 2%;
  margin: 0;
  width: 100%;
  font-size: 14px;
  cursor: pointer;
}

.stat-actions div.active {
  font-weight: bolder;
  background-color: white;
  /* border-left: 1px solid black; */
  /* border-radius: 5px; */
  padding-top: 1%;
  padding-left: 4%;
  padding-bottom: 1%;
  margin: 0%;
  width: 100%;
  font-size: 14px;
  color: black;
  font-size: 18px;
}

div.stat-actions > div {
  /* border: 1px black solid; */
  background-color: rgba(255, 255, 255, 0.6);
  padding: 4px;
}

div.stat-actions {
  width: 100%;
  padding: 10px;
}

.stat-actions button.cancel {
  font-size: 12px;
}

.stat-actions button.active {
  /* font-weight: bolder; */
  color: white;

  /* background-color: rgb(73, 73, 0); */
  border-radius: 0;
}

.stat-actions button {
  font-size: 12px;
}

@media screen and (min-width: 320px) {
  .stat-actions button {
    font-size: calc(12px + 1 * ((100vw - 320px) / 680));
  }
  .stat-actions div.action > button {
    font-size: 12px;
    min-height: 40px;
  }
}

@media screen and (min-width: 1000px) {
  .stat-actions button {
    font-size: 14px;
  }
  .stat-actions div.action > button {
    font-size: 12px;
  }
}

.stat-actions .header {
  color: white;
  background: rgba(34, 34, 17, 0.8);
  padding: 8px;
  border-top-left-radius: 8px;
}
