div.youtube-upload {
  border: 2px solid black;
  margin: 5%;
  padding: 5%;
  border-radius: 10px;
}

.youtube-upload {
  color: black;
  text-align: center;
}
.youtube-logo {
  width: 99%;
}

.vheader {
  grid-area: header;
}

.vleft {
  grid-area: left;
  background-color: aquamarine;
}
.vright {
  grid-area: right;
}

.vcontainer {
  display: inline-grid;
  gap: 5px;
  grid-template-areas:
    'header header header header'
    'left right right right';
}

input {
  margin-left: 8%;
  width: 80%;
}
