.teamselect button {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.teamselect {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.teamselect-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.teamselect-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.teamselect-content a:hover {background-color: #f1f1f1}

/* Show the dropdown menu on hover */
.teamselect:hover .teamselect-content {
  display: block;
  overflow-y: auto;
  max-height: 200px;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.teamselect:hover .dropbtn {
  background-color: #3e8e41;
}

.teamselect-outer {
    overflow: hidden;
}