* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/*reset all browser style*/

:root {
  min-height: 100vh;
  width: 100vw;
}

/*set the root element to viewport*/

body {
  /* We will never scroll */
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #08074b;
  color: white;
  background-color: black;
}

a {
  color: white;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  /* color: white; */
}

.link-button:hover,
.link-button:disabled,
.link-button:focus {
  text-decoration: none;
}

.link-button:disabled {
  cursor: default;
}

.logo-text {
  float: left;
  font-weight: bolder;
  vertical-align: text-bottom;
  font-size: 20px;
  color: #fff;
}

.logo-image {
  float: left;
  padding: 4px;
}

.logo {
  padding-left: 4px;
  padding-top: 12px;
  padding-right: 20px;
  padding-bottom: 0px;
}

.top-bar a {
  outline: none;
}

.top-bar {
  padding-top: 0px;
  padding-right: 20px;
  background-color: rgba(34, 34, 17, 0.8);
}

.navigation a {
  color: black;
  font-size: 1.2em;
  font-weight: bold;
  text-decoration: none;
}

.nav-item {
  background-color: black;
  float: right;
  color: white;
  text-align: center;
  padding: 12px;
  margin: 2px;
  font-weight: bolder;
  font-size: 13px;
  border-radius: 2px;
  border: 1px solid white;
}

button.nav-item:focus {
  outline: 0;
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

/* input[type="text"] {
  background-color: black;
  border-radius: 2px;
  border-color: #333;
  color: #aaa;
} */

div.game-thumbnail-overview {
  border: solid black 3px;
  width: 90%;
  height: 90%;
  margin-left: 3%;
}

div.game-list {
  width: 100%;
}

div.game-list-row {
  width: 100%;
  background-color: #fab;
}

div.game-list-cell {
  height: 100%;
  float: left;
}

div.video-thumbnail {
  position: relative;
  /* border: 4px #08074B solid; */
}

.halfwidth {
  width: 50%;
  float: left;
}

.thirdwidth {
  width: 33.33%;
  float: left;
}

.game-overview-title {
  position: absolute;
  bottom: 9px;
  padding: 2px 4px 4px 10px;
  width: 94%;
  border-left: 1px solid #aaf;
  border-top: 1px solid #aaf;
  margin-left: 2px;
  background-color: rgb(5, 4, 43);
  border-radius: 6px 0px 0px 6px;
  /* background-color: rgb(167, 89, 0); */
  /* background-color: rgb(58, 22, 58); */
}

.game-overview-info div {
  font-size: 11px;
  color: #fff;
}

.container {
  font-size: 14px;
}

button.as-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #434749;
  text-decoration: underline;
  cursor: pointer;
}

.dark button.as-link {
  color: #99a1a7;
  text-decoration: none;
}

.container input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #888;
  background: transparent;
}

.dark .container input,
.dark .container select {
  color: white;
}

/* .container input[type="checkbox"] {
  -webkit-appearance: none;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 15px;
  width: 80px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
}

.container input[type="checkbox"]:active,
input[type="checkbox"]:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.container input[type="checkbox"]:checked {
  background-color: #e9ecee;
  border: 1px solid #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7;
}

.container input[type="checkbox"]:after {
  content: "\2606 Starter";
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 10px;
  top: 5px;
  color: #ccc;
}

.container input[type="checkbox"]:checked:after {
  content: "\2605   Starter";
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 10px;
  top: 5px;
  color: #434749;
}
.container textarea:focus,
input:focus,
select:focus {
  outline: none;
}

.container select {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  border-bottom: 1px solid #888;
  background: transparent;
}
 */

.team.home {
  float: left;
}

.team.away {
  float: right;
}

/* Game containement */
.game .video-player {
  margin: 0px;
  width: calc(70vw - 16px);
  min-width: 400px;
  float: left;
}

.game .control {
  margin: 8px;
  width: calc(30vw - 16px);
  min-width: 210px;
  float: left;
}

@media screen and (max-width: 1200px) {
  .game .video-player {
    width: calc(100vw - 16px);
    float: none;
  }

  .game .control {
    width: calc(100vw - 16px);
    float: none;
  }
}

.team-name,
.action-header {
  color: black;
  font-weight: bold;
  background-color: white;
  text-align: center;
  font-size: 16px;
  padding: 1%;
}

/* 
  Make the video player auto size based on container
*/
div.video-player>div {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 53.25%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}



.grid7 {
  width: 100%;
  display: inline-grid;
  grid-template-columns: auto auto auto auto auto auto;
}

.grid3 {
  width: 100%;
  display: inline-grid;
  grid-template-columns: auto auto auto;
}

.grid2 {
  display: inline-grid;
  grid-template-columns: 50% 50%;
}

.grid1 {
  display: inline-grid;
  grid-template-columns: auto;
}

div.component {
  /* background-color: black; */
  margin: 0%;
  padding: 0%;
  border-bottom: 2px solid black;
}

.team input.number {
  width: 4%;
}

.component-header {
  background-color: rgba(34, 34, 17, 0.8);
  font-weight: bolder;
  padding: 1%;
}

.dark .component-header {
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  border-bottom: solid white 1px;
}

.component-content {
  background-color: rgba(244, 244, 244, 0.78);
  color: black;
  padding: 1%;
}

.dark .component-content {
  color: white;
  background-color: rgba(34, 34, 17, 0.9);
}

.error input[type='text'] {
  /* outline: none; */
  /* border: 2px solid red; */
  background-color: red;
}

.error {
  font-size: 14px;
  color: darkred;
}

.container {
  width: 100%;
  /* background: rgba(255, 255, 255, 0.4); */
}

/* .auto-width {
  width: auto;
} */

div.pick-new-or-existing-team-or-not {
  display: flex;
  justify-content: center;
  width: 100%;
}

.pick-new-or-existing-team-or-not label {
  font-size: large;
  font-weight: bold;
}

.pick-new-or-existing-team-or-not button {
  margin: 4px;
  width: 300px;
  height: 70px;
}

button .small-text {
  font-size: small;
}
