.overcolor {
  background-color: rgba(255, 255, 255, 0.7);
  min-height: 100vh;
}

.overcolor.dark {
  background-color: rgba(8, 7, 75, 0.6);
}

div.page {
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url('bak-stor.jpg');
  min-height: 100vh;
}
